import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "receipt-design"
    }}>{`Receipt Design`}</h1>
    <h3 {...{
      "id": "beer-ingredient-classify"
    }}>{`Beer ingredient classify`}</h3>
    <p>{`From: Mastering Homebrew: The Complete Guide to Brewing Delicious Beer
`}<img alt="beer ingredient classify" src="https://sommbeer.com/wp-content/uploads/2019/06/Beer-Color-1.jpg" /></p>
    <p>{`How to make the composition?
`}<img alt="beer composition" src="https://sommbeer.com/wp-content/uploads/2019/06/Grains-1.jpg" /></p>
    <p>{`Beer flavors
`}<img alt="flavors" src="https://sommbeer.com/wp-content/uploads/2019/06/Flavor.jpg" /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      